/* eslint-disable no-undef */
// mail.php
$(document).ready(function () {
    // E-mail Ajax Send
  $('form').submit(function () { // Change
    var th = $(this)
    $.ajax({
      type: 'POST',
      url: './mail.php', // Change
      data: th.serialize()
    }).done(function () {
      alert('Спасибо за заявку!')
      setTimeout(function () {
                // Done Functions
        th.trigger('reset')
      }, 1000)
    })
    return false
  })
})
// loader
s0 = setTimeout(function () {
  $('.logo-line div').css({width: '37%'})
}, 100)
s1 = setTimeout(function () {
  $('.logo-line div').css({width: '57%'})
}, 400)
s2 = setTimeout(function () {
  $('.logo-line div').css({width: '77%'})
}, 600)
window.onload = function () {
  clearTimeout(s0)
  clearTimeout(s1)
  clearTimeout(s2)

  $('.logo-line div').css({width: '100%'})
  setTimeout(function () {
    $('.loader').fadeOut(1000)
  }, 1000)
}
// scrool

// fancybox
$(document).ready(function () {
  $('.fancy').fancybox({
    'transitionIn':	'elastic',
    'transitionOut':	'elastic',
    'speedIn':	600,
    'speedOut':	200,
    'scrolling': false,
    'overlayShow':	false
  })
})
// fulpage.js
if ($(window).width() <= 600) {
  $('.fullpage').fullpage({
    anchors: ['block1', 'block2', 'block3', 'block4', 'block5', 'footer'],

    autoScrolling: false,
    responsiveWidth: 600
  })
  $(function () {
    $(window).scroll(function (event) {
      if ($(this).scrollTop() > 50) {
        $('.fixed-logo').css('top', '3vw')
        $('.mobile-menu-back').fadeIn()
        $('.logo-white').fadeOut(0)
        $('.mobile-logo').fadeIn(0)
        $('.blmenu').hide()
        $('.wmenu').show()
        $('.bphone').hide()
        $('.wphone').show()
        $('.mobile-menu').css('top', '5vw')
      } else {
        $('.fixed-logo').css('top', '8vw')
        $('.mobile-menu-back').fadeOut()
        $('.mobile-logo').fadeOut(0)
        $('.logo-white').fadeIn(0)
        $('.blmenu').show()
        $('.wmenu').hide()
        $('.bphone').show()
        $('.wphone').hide()
        $('.mobile-menu').css('top', '10vw')
      }
    })
  })
} else {
  $('.fullpage').fullpage({
    menu: '#menu',
    anchors: ['block1', 'block2', 'block3', 'block4', 'block5', 'footer'],
    easing: 'easeInOutCubic',
    navigation: false,
    scrollBar: true,
    css3: true,
    scrollingSpeed: 1300,
    fitToSectionDelay: 900,
    normalScrollElements: '.popup, .sc-window, .research-window',
    onLeave: function (index, nextIndex, direction) {
      setTimeout(function () {
        switch (nextIndex) {
          case 1:
            $('.fixed-menu').removeClass('black-menu')
            $('.fixed-menu .button-main-bordered').removeClass('black')
            $('.inst-white').show()
            $('.inst-black').hide()
            $('.logo-white').show()
            $('.logo-black').hide()
            $('.fixed-menu').css({'transform': 'translateY(0)', 'top': '0'})

            break
          case 2:
            $('.fixed-menu').addClass('black-menu')
            $('.fixed-menu .button-main-bordered').addClass('black')
            $('.inst-white').hide()
            $('.inst-black').show()
            $('.logo-white').show()
            $('.logo-black').hide()
            $('.fixed-menu').css({'transform': 'translateY(0)', 'top': '0'})
            break
          case 3:
            $('.fixed-menu').removeClass('black-menu')
            $('.fixed-menu .button-main-bordered').removeClass('black')
            $('.inst-white').show()
            $('.inst-black').hide()
            $('.logo-white').show()
            $('.logo-black').hide()
            $('.fixed-menu').css({'transform': 'translateY(0)', 'top': '0'})
            break
          case 4:
            $('.fixed-menu').removeClass('black-menu')
            $('.fixed-menu .button-main-bordered').removeClass('black')
            $('.inst-white').show()
            $('.inst-black').hide()
            $('.logo-white').hide()
            $('.logo-black').show()
            $('.fixed-menu').css({'transform': 'translateY(0)', 'top': '0'})

            break
          case 5:
            $('.fixed-menu').addClass('black-menu')
            $('.fixed-menu .button-main-bordered').addClass('black')
            $('.inst-white').hide()
            $('.inst-black').show()
            $('.logo-white').hide()
            $('.logo-black').show()
            $('.fixed-menu').css({'transform': 'translateY(0)', 'top': '0'})

            break
          case 6:
            $('.fixed-menu').css({'transform': 'translateY(-46vh)', 'top': 'unset'})
            break
        }
      }, 700)
    }
  })

  $('[data-window]').click(function () {
    var id = $(this).data('window')

    $('.popup').removeClass('active')
    $('.section').addClass('goback')
    $(id).addClass('active')

    $('body').css('overflow', 'hidden')

    return false
  })
}

// owl.carousel

// МАЛЫЙ КАТАЛОГ
var smCatalog = $('.catalog-slider')

smCatalog.owlCarousel({
  items: 1,
  loop: true

})
$('.catalog-slider-next').click(function () {
  smCatalog.trigger('next.owl.carousel')
})
$('.catalog-slider-back').click(function () {
  smCatalog.trigger('prev.owl.carousel')
})

/// /////////////////////////////////////////

// КЛИЕНТЫ
var clients = $('.clients-slider')

clients.owlCarousel({
  items: 1,
  loop: true
})
$('.clients-slider-next').click(function () {
  clients.trigger('next.owl.carousel')
})
$('.clients-slider-back').click(function () {
  clients.trigger('prev.owl.carousel')
})

$('#close-mobile-menu').click(function () {
  $('.mobile-menu-opened').hide()
  $('html').css('overflow', 'unset')
})
$('.close1').click(function () {
  $('.mobile-menu-opened').hide()
  $('html').css('overflow', 'unset')
})
$('#menu-mobile').click(function () {
  $('.mobile-menu-opened').fadeIn()
  $('html').css('overflow', 'hidden')
})

$('[data-window]').click(function () {
  var id = $(this).data('window')

  $('.popup').removeClass('active')
  $('.section').addClass('goback')
  $(id).addClass('active')

  $('body').css('overflow', 'hidden')

  return false
})

$('.close-all').click(function (e) {
  e.preventDefault()
  $('.popup').removeClass('active')
  $('.section').removeClass('goback')
  $('body').css('overflow', 'auto')
  return false
})
$(function () {
  $('.form-phone').mask('+7 (999) 999-9999')
})